.person-details{
    border: 3px solid blue;
    padding: 10px;
    border-radius: 5px;
    background-color: transparent!important;
    margin: 3px;
    height: 80vh !important;
    overflow-y: scroll;
    .field-wrapper{
        margin-block: 0.5rem;
        display: flex;
        flex-direction: column;
        flex: 1 20%;
        @media(max-width: 576px){
            flex: 1 25%;
        }
        .heading-field{
            font-family: 'tajawal_medium';
            font-weight: 500;
            color: darkblue;
        }
        .data-field{
            font-weight: 600;
        }
    }
}