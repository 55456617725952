.login-error-message{
    color: #842029;
    margin-block: 1rem;
    border: 1px solid #f20b22;
    border-radius: 0.375rem;
    padding: 0.2rem;
    background-color: #f8d7da;
}
.login-form{
    form div input{
        color: black !important;
        -webkit-text-fill-color: black !important;
    }
    input [type="password"]{
        color: black !important;
        -webkit-text-fill-color: black !important;

    }
}
