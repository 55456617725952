.get-post-entity{
    .dropdown-icon__wrapper{
        display: flex;
        .plus-getPost{
            width: 40px !important;
            height: 40px;
            border: 2px solid blue;
            border-radius: 50%;
            justify-content: center;
            text-align: center;
            background: aquamarine;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        select{
            display: flex;
            margin-inline-end: 0.5rem;
        }
    }
    .add-form{
        margin-top: 1rem;
    }
}