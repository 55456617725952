.add-person__card{
    border: 3px solid blue;
    padding: 10px;
    border-radius: 5px;
    background-color: transparent!important;
    margin: 3px;
    height: 80vh !important;
    overflow-y: scroll;
    .servant-header{
        transition: all 1s ease-in;
    }
}
.error-field > .form-label{
    color: $form-invalid-field;
}
.error-field > input{
    border-color: $form-invalid-field;
}
.error-field > input:focus{
    border-color: $form-invalid-field;
    box-shadow: 0 0 0 0.1rem $form-invalid-field;
}
.error-message{
    display: none;
    color: #842029;
    margin-block: 1rem;
    border: 1px solid #f20b22;
    border-radius: 0.375rem;
    position: relative;
    padding: 0.2rem;
    background-color: #f8d7da;
}
.error-field > .error-message{
    display: block;
    transition: display 0.5s;
}
.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0px;
    margin-inline-start: -1px;
    border-radius: 0;
    border-end-end-radius: 0;
    border-end-start-radius: 0;
}
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control, .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select, .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-radius: 0;
}