// .bg-layer>div{
//     width: 100% !important;
// }

// .bg-img{
//     background-image: url('../../../assets/img/background.png');
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: cover;
//     padding: 0;
// }

// .bg-layer {
//     width: 100%;  
//     height: 100vh;
//     display: -webkit-box;
//     display: -webkit-flex;
//     display: -moz-box;
//     display: -ms-flexbox;
//     // display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
//     // padding: 15px;
//     // position: relative;
//     z-index: 1; 
//     background-color: rgba(255,255,255,0.7); 
// }
  
// .bg-layer::before {
//     content: "";
//     display: block;
//     position: absolute;
//     z-index: -1;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
//     background-color: rgba(255,255,255,0.7);
// }

div#root{
    background-image: url('../../../assets/img/background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 0;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
}
// body{
//     background-image: url('../../../assets/img/background.png');
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: cover;
//     padding: 0;
//     position: absolute;
//     z-index: 100;
//     min-width: 100%;
//     min-height: 100%;
// }
.app-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    flex-wrap: wrap;
    justify-content: center;
    background-color: rgba(255,255,255,0.7); 
    position: absolute;
    z-index: 101;
    width: 100%;
    height: 100%;
}
// .app-wrapper {
//     background-image: url('../../../assets/img/background.png');
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: cover;
//     padding: 0;
//     position: absolute;
//     z-index: 100;
//     width: 100%;
//     height: 100%;
//     div{
//         display: -webkit-box;
//         display: -webkit-flex;
//         display: -moz-box;
//         display: -ms-flexbox;
//         flex-wrap: wrap;
//         justify-content: center;
//         background-color: rgba(255,255,255,0.7); 
//         position: absolute;
//         z-index: 101;
//         width: 100%;
//         height: 100%;  
//     }
// }
// .bg-img{
//     // display: -webkit-box;
//     // display: -webkit-flex;
//     // display: -moz-box;
//     // display: -ms-flexbox;
//     // flex-wrap: wrap;
//     // justify-content: center;
//     background-color: rgba(255,255,255,0.7); 
//     // position: absolute;
//     // z-index: 101;
//     // width: 100%;
//     // height: 100%;
// }
