.stg-phone{
    .phone-container{
        display: flex;
        margin-bottom: 7px;
        .plus-phone{
            width: 40px !important;
            height: 40px;
            border: 2px solid blue;
            border-radius: 50%;
            justify-content: center;
            text-align: center;
            background: aquamarine;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:focus-visible {
                color: #fff;
                background-color: #0b5ed7;
                border-color: #0b5ed7;
                outline: 0;
                box-shadow: 0 0 0 0.25rem rgba(49,132,253, .5);
            }
        }
    }
    .error-message{
        display: block;
    }

}