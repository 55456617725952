.studies__create-modal__form__header1{
    font-size: 15px;
}
.studies__create-modal__form__div1,.studies__create-modal__form__div2{
    border-radius: 5px;
    padding: 10px;
    border: 2px solid blue;
}
.studies-stage-wrapper{
    gap: 10px;
    .studies-stage-drop-down{
        #dropdown-basic-button{
            width: 100%;
        }
    }
}
.selected-radio{
    border: 2px solid black;
    background-color: antiquewhite !important;
    transition: border 0.5s;
    transition: background-color  0.5s;
}