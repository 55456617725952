.update-person{
    border: 3px solid blue;
    border-radius: 5px;
    background-color: transparent!important;
    height: 80vh !important;
    overflow-y: auto;
    .tab-wrapper{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        box-sizing: border-box;
        .tab{
            display: flex;
            flex: 1 25%;
            background-color: white;
            margin-block-end: 10px;
            min-height: 40px;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            margin-block-end: 10px;
            border-block-end: 2px solid green;
            @media (max-width:1100px){
                flex: 1 50%;
            }
            &.selected{
                border-block-end: 3px solid blue;
                background: aqua;
            }
            &:not(.disabled):not(.selected):hover{
                background: aliceblue;
                cursor: pointer;
            }
            &.disabled{
                opacity: 0.5;
                cursor:not-allowed
            }
        }
        .tab:last-child{
            margin-inline-end: 0px;
        }
    }
    .field-wrapper{
        margin-block: 0.5rem;
        display: flex;
        flex-direction: column;
        flex: 1 20%;
        @media(max-width: 576px){
            flex: 1 25%;
        }
        
    }
}
[dir='rtl'] .update-person{
    input{
        writing-mode: rl;
    }
}

